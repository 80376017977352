import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitle from "../components/pagetitle"

const AboutPage = () => (
  <Layout>
    <PageTitle title="Meet Sean Becker" page="PageTitleAbout" />
    <Seo title="Meet Sean Becker, MD Attorney" description="Meet Sean E Becker, an Annapolis attorney with over 10 years of family law experience to help you through your situation. " />
    <div className="content-page-wrapper">
        <div className="page-intro">
            <p>
            Sean Becker, a local to Annapolis and graduate of Broadneck high school, began his practice in Annapolis in 2008 after graduating from the University of the District of Columbia David A. Clarke School of Law and admission to the Maryland State Bar Association that same year. During law school, Sean interned at The U.S. Attorney's Office in Las Vegas, NV. He also interned at the Public Defender's Office in Las Vegas, NV and in Anne Arundel County, MD gaining experience defending various criminal offenses.
</p><p>
Prior to Law School, Sean worked as a court reporter and a court reporter manager in various legal arenas across the country. Working as a court reporter has given Sean a unique view of the legal system that he brings to each case. Sean’s experience in the various Federal and State arenas has provided the foundation necessary to work with his clients on their individual legal matters.
</p><p>
Sean has worked with clients in Maryland since beginning his practice in 2008. Though primarily working in Anne Arundel, Queen Anne’s and Baltimore Counties, Sean has handled several cases across the State from Snow Hill to Cumberland, MD. Sean works directly with his clients, evaluating all issues and discussing potential outcomes, so that each client can make informed decisions pertaining to their legal matters.
            </p>
        </div> 
    </div>        
  </Layout>
)

export default AboutPage
